<template data-app>
<div class="d-flex flex-column flex-root">
    <div
      class="login login-1 login-signin-on d-flex flex-column  flex-row-fluid"
      id="kt_login"
    >
      <!--begin::Content-->
      <KTHeader></KTHeader>
      <div
        class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
      >
        <div class="d-flex flex-column-fluid flex-center mt-20 mb-10">
            <v-app>
              <!--begin::Content header-->
            
              <!--end::Content header-->


                <!--begin::Signup-->
                <div class="register-form">
                  <div class="step-header">
                      <div class="step-header-title">
                    
                                <v-icon v-if="step != 1" @click="beforeStep()">mdi-arrow-left</v-icon>
                                <v-icon v-if="step == 1">ss</v-icon>
                                <p v-if="step !=3">Registro</p><p v-if="step ==3">Seguridad</p><v-icon @click="landing">mdi-close</v-icon>
                      </div>
                      <div>
                        <div class="steps">
                          <v-btn class="step1-button"  fab>
                            <span v-if="step != 1" class="material-icons" style="font-size:35px;">
                            check_circle
                            </span>
                            <span v-if="step == 1" class="material-icons" style="font-size:35px;">
                            face
                            </span>
                          </v-btn><hr style="border-top: 2px dotted black"/>
                            <v-btn class="step2-button" v-bind:style=" step !=1  ? 'background-color: #5F3FB1 !important;' :'background-color: #CBD5E1 !important;' " fab>
                              <span v-if="step == 3" class="material-icons" style="font-size:35px;">
                              check_circle
                              </span>
                              <span v-if="step != 3"  class="material-icons" style="font-size:35px;">
                                supervisor_account
                              </span>
                          </v-btn><hr style="border-top: 2px dotted black"/>
                            <v-btn class="step3-button"  v-bind:style=" step ==3  ? 'background-color: #5c77e5 !important;' :'background-color: #CBD5E1 !important;' "  fab dark>
                            <span class="material-icons" style="font-size:35px;">
                            lock
                            </span>
                          </v-btn>
                        </div>
                        <div class="step-title">
                          <p>Información del estudiante</p>
                          <p>Información parental</p>
                          <p>Información de la cuenta</p>
                        </div>
                                  
                      </div>
                  </div>
                  <div class="step-body">
                      <div class="step1" v-if="step == 1">
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  label="Nombre"
                                  v-model="form.firstName"
                                  prepend-inner-icon="mdi-account"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  label="Apellido"
                                  v-model="form.lastName"
                                  prepend-inner-icon="mdi-account"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  label="Fecha de nacimiento"
                                  v-model="form.birthday"
                                  prepend-inner-icon="mdi-face"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-select
                                  v-model="form.gender"
                                  :items="['masculino', 'femenino']"
                                  label="Género"
                                  outlined
                                  single-line
                                  prepend-inner-icon="mdi-face"
                                ></v-select>
                            </v-col>
                          </v-row>
                          <div class="d-flex continue">
                            <router-link to="login" class="login-des">¿Ya tienes una cuenta?</router-link>
                            <v-btn rounded   style="width:200px !important"  class="text-capitalize contiuneButton" @click="nextStep(2)">Continuar<span class="material-icons">arrow_forward</span></v-btn>
                          </div>
                        
                      </div>
                      <div class="step2" v-if="step == 2">
                        <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  label="Nombre del padre/madre/Tutor"
                                  v-model="form.parentFirstName"
                                  prepend-inner-icon="mdi-account"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  v-model="form.parentLastName"
                                  label="Apellido del padre/madre/Tutor"
                                  prepend-inner-icon="mdi-account"
                                ></v-text-field>
                            </v-col>
                            <div class="register-border"></div>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  v-model="form.country"
                                  label="País"
                                  prepend-inner-icon="mdi-map-marker"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  label="Ciudad"
                                  v-model="form.city"
                                  prepend-inner-icon="mdi-map-marker"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  v-model="form.parentPhoneNumber"
                                  label="Número de celular / Whats App"
                                  prepend-inner-icon="mdi-phone"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  v-model="form.parentEmail"
                                  label="Correo electrónico"
                                  prepend-inner-icon="mdi-email"
                                ></v-text-field>
                            </v-col>
                          </v-row>
                          <div class="d-flex continue">
                            <router-link to="#" v-if="step == 1">¿Ya tienes una cuenta?</router-link>
                            <v-icon v-if="step == 2">xxxx</v-icon>
                            <v-btn rounded  style="width:200px !important" class="text-capitalize contiuneButton" @click="nextStep(3)">Continuar<span class="material-icons">arrow_forward</span></v-btn>
                          </div>
                      </div>
                      <div class="step3" v-if="step == 3">
                        <v-form ref="form" lazy-validation
                          @submit.prevent="onSubmit">
                          <v-row>
                            <v-col cols="12">
                              <div
                                role="alert"
                                v-bind:class="{ 'd-none': !errors.length, 'show': errors.length}"
                                class="alert alert-danger mt-4"
                              >
                                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                                  {{ error }}
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                  outlined
                                  single-line
                                  v-model="form.email"
                                  label="Correo electrónico*"
                                  :rules="form.emailRules"
                                  prepend-inner-icon="mdi-account"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  type="password"
                                  v-model="form.password"
                                  label="Contraseña*"
                                  :rules="form.passwordRules"
                                  prepend-inner-icon="mdi-email"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  type="password"
                                  v-model="form.confirmPassword"
                                  label="Confirmar Contraseña*"
                                  :rules="[passwordConfirmationRule]"
                                  prepend-inner-icon="mdi-email"
                                ></v-text-field>
                            </v-col>
                          </v-row>
                          <div class="login-action d-flex continue">
                            <div>
                              <ul>
                                <li class="py-1">8 dígitos</li>
                                <li class="py-1">Al menos una mayúscula</li>
                              </ul> 
                            </div>
                            <button  ref="kt_login_signup_submit"
                                class="signup-button btn btn-success text-capitalize font-weight-bold py-4 px-24 mb-5 font-size-3 rounded-pill">Registrar y comenzar</button>
                          </div>
                        </v-form>

                      </div>
                  </div>
                  
                </div>
                <!--end::Signup-->

            </v-app>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/login/register.scss";
@import "@/assets/sass/pages/login/login-1.scss";
</style>


<script>
import { mapState } from "vuex";
import { REGISTER } from "@/core/services/store/auth.module";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTHeader from "@/view/layout/header/Header.vue";
import Swal from 'sweetalert2'

export default {
  name: "register",
  components: {
    KTHeader,
  },
  data() {
    return {
      // Remove this dummy login info
      form: {
        firstName: "",
        gender: "masculino",
        lastName: "",
        birthday: "",
        parentFirstName: "",
        parentLastName: "",
        country: "",
        city: "",
        parentPhoneNumber: "",
        parentEmail: "",
        email: "",
        password: "",
        confirmPassword: "",
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        passwordRules: [
          v => !!v || "Password is required",
          v => (v && v.length > 5) || "Password must be bigger than 8 characters",
        ],
      },
      step:1,
      step1:false,
      step2:false,
    };
  },
  methods: {
    landing() {
      this.$router.go(-1)
    },
    onSubmit() {

      if (!this.$refs.form.validate()) {
        Swal.fire('Something went wrong.')

        return;
      }

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            // send register request
        this.$store
          .dispatch(REGISTER, {
            first_name: this.form.firstName,
            last_name: this.form.lastName,
            email: this.form.email,
            birthday: this.form.birthday,
            gender: this.form.gender,
            county: this.form.country,
            city: this.form.city,
            parent_first_name: this.form.parentFirstName,
            parent_last_name: this.form.parentLastName,
            parent_phone_number: this.form.parentPhoneNumber,
            parent_email: this.form.parentEmail,
            password: this.form.lastName,
          }).then(() => {
             submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire('Se han proporcionado los datos de forma correcta. Cualquier duda o comentario estamos a la oden en Whatsapp. En breve tu registro será autorizado, para que puedas comenzar el 1o de octubre. GRACIAS')
            this.$router.push({ name: "login" });
          }).catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
    },
    nextStep(val) {
      this.step = val;
    },
    beforeStep() {
      var step = this.step;
      this.step = step - 1;
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    passwordConfirmationRule() {
      return () => (this.form.password === this.form.confirmPassword) || 'Password must match'
    }
  }
};
</script>
